<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-dollar-sign"></i> ข้อมูลผูกสินค้ากับร้านค้า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-store-mapping-product/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import pageStoreMappingProduct from "./view/page.store_mapping_product.vue";

  export default {
    name: 'store_mapping_product-view-page-store_mapping_product',
    components: {
      pageStoreMappingProduct
    }
  };
</script>
<style>

</style>
